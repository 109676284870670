<template>
  <b-row>
    <b-col xxs="12" lg="12" xl="12" class="pr-6 display">
      <b-card class="mb-12 mr-12" :title="title">
        <b-form
          @submit.stop.prevent="onValidateRoundRobinFormSubmit"
          class="av-tooltip tooltip-label-top"
        >
          <b-row>
            <b-col sm="6">
              <b-form-group label="Employee" class="lg">
                <b-form-select
                  v-model.trim="$v.roundRobin.employeeID.$model"
                  :options="roundRobinTypeID === 1 ? accreditationEmployees : employees"
                  value-field="employeeID"
                  text-field="displayName"
                  plain
                  :state="validateRoundRobinState('employeeID')"
                ></b-form-select>
                <div
                  class="text-danger"
                  v-if="
                    !$v.roundRobin.employeeID.required &&
                      $v.roundRobin.employeeID.$dirty
                  "
                >
                  Field is required
                </div>
              </b-form-group>
            </b-col>
            <!-- Hidden per PBI 356
            <b-col sm="6">
              <b-form-group label="State" class="lg">
                <multiselect
                  :ref="'statemultiselect'"
                  :value="roundRobin.states"
                  :options="updatedStates"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select States"
                  track-by="stateID"
                  @select="
                    ev => handleSelect(ev, 'states', 'statemultiselect', states)
                  "
                  @remove="ev => handleRemove(ev, 'states', 'stateID')"
                  :custom-label="roundRobinStateCustomLabel"
                ></multiselect>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Programs">
                <multiselect
                  :ref="'programmultiselect'"
                  :value="$v.roundRobin.nonCorporatePrograms.$model"
                  :multiple="true"
                  :options="groupedPrograms"
                  group-values="options"
                  group-label="label"
                  :close-on-select="false"
                  placeholder="Select Programs"
                  track-by="programID"
                  @select="
                    ev =>
                      handleSelect(
                        ev,
                        'nonCorporatePrograms',
                        'programmultiselect',
                        programs
                      )
                  "
                  @remove="
                    ev => handleRemove(ev, 'nonCorporatePrograms', 'programID')
                  "
                  :custom-label="roundRobinProgramCustomLabel"
                  :class="{  'multiselect-is-invalid' : $v.roundRobin.nonCorporatePrograms.$error }"
                ></multiselect>
                <div
                  class="text-danger"
                  v-if="$v.roundRobin.nonCorporatePrograms.$error"
                >
                  Field is required
                </div>
              </b-form-group>
            </b-col>
            <!-- Hidden per PBI 356
            <b-col sm="6">
              <b-form-group label="Corporate Programs" class="lg">
                <multiselect
                  :ref="'corprogmultiselect'"
                  :value="roundRobin.corporatePrograms"
                  :options="updatedCorprograms"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Corporate Programs"
                  track-by="programID"
                  @select="
                    ev =>
                      handleSelect(
                        ev,
                        'corporatePrograms',
                        'programmultiselect',
                        programs
                      )
                  "
                  @remove="
                    ev => handleRemove(ev, 'corporatePrograms', 'programID')
                  "
                  :custom-label="roundRobinCorProgramCustomLabel"
                ></multiselect>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group label="Affiliate" class="lg">
                <multiselect
                  v-model="roundRobin.affiliates"
                  :options="consultants"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Affiliates"
                  track-by="affiliateID"
                  label="companyName"
                ></multiselect>
              </b-form-group>
            </b-col>
            <!-- Hidden per PBI 356
            <b-col sm="6">
              <b-form-group label="Application Types" class="lg">
                <multiselect
                  :ref="'applicationtypemultiselect'"
                  :value="roundRobin.applicationTypes"
                  :options="updatedAppTypes"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Application type"
                  track-by="applicationTypeID"
                  @select="
                    ev =>
                      handleSelect(
                        ev,
                        'applicationTypes',
                        'applicationtypemultiselect',
                        applicationTypes
                      )
                  "
                  @remove="
                    ev =>
                      handleRemove(ev, 'applicationTypes', 'applicationTypeID')
                  "
                  :custom-label="appTypeCustomLabel"
                ></multiselect>
              </b-form-group>
            </b-col> -->
          </b-row>
          <template v-if="roundRobinTypeID === 1">
            <b-row >
              <b-col sm="6">
                <b-form-group label="State" class="lg">
                  <multiselect
                    v-model="roundRobin.states"
                    :options="states"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select State"
                    track-by="stateID"
                    label="stateCode"
                  ></multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Application Type" class="lg">
                  <multiselect
                    v-model="roundRobin.applicationTypes"
                    :options="applicationTypes"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Application Type"
                    track-by="applicationTypeID"
                    label="applicationTypeDescription"
                  ></multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Hidden per PBI/1272
            <b-row>
              <b-col sm="6">
                <b-form-group label="Corporate Programs" class="lg">
                  <multiselect
                  :ref="'corporateprogrammultiselect'"
                  :value="roundRobin.corporatePrograms"
                  :multiple="true"
                  :options="groupedPrograms"
                  group-values="options"
                  group-label="label"
                  :close-on-select="false"
                  placeholder="Select Programs"
                  track-by="programID"
                  @select="
                    ev =>
                      handleSelect(
                        ev,
                        'corporatePrograms',
                        'corporateprogrammultiselect',
                        programs
                      )
                  "
                  @remove="
                    ev => handleRemove(ev, 'corporatePrograms', 'programID')
                  "
                  :custom-label="roundRobinProgramCustomLabel"
                ></multiselect>
                </b-form-group>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col sm="6">
                <b-form-group class="lg">
                  <b-form-checkbox
                    id="corporate"
                    name="corporate"
                    v-model="roundRobin.corporate"
                    >
                    Corporate
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col sm="3">
              <!-- Enabled version of isDefault toggle, b/c either there is no defaultEmployee already, or it's THIS employee -->
              <b-form-group v-if="roundRobin.defaultEmployeeID==null || roundRobin.defaultEmployeeID==roundRobin.employeeID" label="Default?">
                <switches
                  v-model="roundRobin.isDefault"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
              <!-- Disabled version of isDefault toggle with tooltip, b/c someone else is already the default -->
              <b-form-group v-if="roundRobin.defaultEmployeeID!=null && roundRobin.defaultEmployeeID!=roundRobin.employeeID" label="Default?">
                <switches
                  v-model="roundRobin.isDefault"
                  theme="custom"
                  color="primary"
                  disabled
                  v-b-tooltip.hover.right
                  :title="`You cannot set this employee as default because ${this.roundRobin.defaultEmployeeName} is already the default.`"
                ></switches>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group label="Active">
                <switches
                  v-model="roundRobin.active"
                  theme="custom"
                  color="primary"
                ></switches>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Created Date:">
                <p>{{ roundRobin.createdDate }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Created By:">
                <p>{{ roundRobin.createdByName }}</p>
              </b-form-group>
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx lg="3">
              <b-form-group label="Edited Date:">
                <p>{{ roundRobin.lastModifiedDate }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Edited By:">
                <p>{{ roundRobin.lastModifiedByName }}</p>
              </b-form-group>
            </b-colxx>
            <b-colxx lg="3">
              <b-form-group label="Last Assigned Date:">
                <p>{{ roundRobin.lastAssignedDate }}</p>
              </b-form-group>
            </b-colxx>
          </b-row>

          <b-row align-h="left">
            <b-col xl="2" lg="4">
              <b-button
                block
                variant="danger"
                class="mt-4"
                size="lg"
                @click="cancelRoundRobinForm()"
                >{{ $t('forms.cancel') }}
              </b-button>
            </b-col>
            <b-col  lg="4" xl="2">
              <b-button
                block
                type="submit"
                variant="success"
                class="mt-4"
                size="lg"
                >{{ $t('forms.submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>
<script>
import AffiliateMixin from '../../../../../mixins/AffiliateMixin.vue'
import CompanyMixin from '../../../../../mixins/CompanyMixin.vue'
import CompanyAddressMixin from '../../../../../mixins/CompanyAddressMixin.vue'
import DirtyDataMixin from '../../../../../mixins/DirtyDataMixin.vue'
import { mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import ProgramMixin from '../../../../../mixins/ProgramMixin.vue'
import RoundRobinMixin from '../../../../../mixins/RoundRobinMixin.vue'
import Switches from 'vue-switches'
import { validationMixin } from 'vuelidate'
const { required } = require('vuelidate/lib/validators')

export default {
  components: {
    switches: Switches,
    multiselect: Multiselect
  },

  props: {
    roundRobinID: {
      type: Number,
      default: 0
    },
    roundRobinTypeID: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      stateCloseOnSelect: false,
      title: null,
      seen: true,
      accreditationEmployees: []
    }
  },
  async created () {
    if (!this.roundRobinID) {
      this.title = `Add New ${this.roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} Round Robin`
      // Check if there's a default employee for this round robin type, so we can enforce the rule that there can only be one default emp per rr type.
      // This same operation is handled automatically by getRoundRobinByID when we're editing.
      var defaultEmp = await this.getDefaultEmployeeForRoundRobinType(this.roundRobinTypeID)
      var defaultEmpID = null
      var defaultEmpName = null
      if (defaultEmp) {
        defaultEmpID = defaultEmp.employeeID
        defaultEmpName = defaultEmp.displayName
      }
      this.roundRobin = {
        isDefault: false,
        employeeID: null,
        active: true,
        roundRobinTypeID: this.roundRobinTypeID,
        lastAssignedDate: null,
        corporatePrograms: [],
        nonCorporatePrograms: [],
        affiliates: [],
        states: [],
        applicationTypes: [],
        defaultEmployeeID: defaultEmpID,
        defaultEmployeeName: defaultEmpName,
        corporate: false
      }
    } else {
      this.title = `Edit ${this.roundRobinTypeID === 1 ? 'Accreditation' : 'Sales'} Round Robin`
      await this.getRoundRobinById(this.roundRobinID)
    }
    this.initDirtyDataWatcher('roundRobin')
    await this.getStates()
    await this.getAllRoundRobinApplicationtype()
    await this.getAllPrograms()
    await this.getAllProgramsByType()
    await this.getAllCertifiedConsultants()
    if (this.roundRobinTypeID === 1) {
      const roleList = [63, 64, 65, 66, 21, 3, 96, 19, 22, 4]
      var AccEmp = []
      const accreditationTypes = await this.getAvailableSalesMembersForAccreditationRoundRobin(this.roundRobin.employeeID, this.roundRobinTypeID, roleList)
      AccEmp = AccEmp.concat(accreditationTypes)
      roleList.every(async (role, index) => {
        if (index === roleList.length - 1) {
          const key = 'employeeID'

          const arrayUniqueByKey = [...new Map(AccEmp.map(item =>
            [item[key], item])).values()]
          this.accreditationEmployees = arrayUniqueByKey

          return false
        }
      })
    } else {
      const roleList = [3, 4, 19, 66]
      await this.getAvailableSalesMembersForRoundRobin(this.roundRobin.employeeID, this.roundRobinTypeID, roleList)
    }
  },
  mixins: [
    validationMixin,
    CompanyAddressMixin,
    RoundRobinMixin,
    CompanyMixin,
    ProgramMixin,
    DirtyDataMixin,
    AffiliateMixin
  ],
  validations: {
    roundRobin: {
      employeeID: {
        required
      },
      nonCorporatePrograms: {
        required
      }
    }
  },
  methods: {
    ...mapMutations(['setPreserveMessageAfterPush']),
    handleSelect (event, ObjKey, refId, options) {
      if (event.text === 'Select All') {
        this.$refs[refId].deactivate()
        this.roundRobin[ObjKey] = _.cloneDeep(options)
      } else if (event.text === 'Unselect All') {
        this.$refs[refId].deactivate()
        this.roundRobin[ObjKey] = []
      } else {
        this.roundRobin[ObjKey].push(event)
      }
    },
    handleRemove (event, ObjKey, key) {
      this.roundRobin[ObjKey] = _.filter(
        this.roundRobin[ObjKey],
        el => el[key] !== event[key]
      )
    },
    roundRobinStateCustomLabel ({ stateID, text }) {
      const states = this.states.find(states => states.stateID === stateID)
      return states ? states.stateName : text || ''
    },
    appTypeCustomLabel ({ applicationTypeID, text }) {
      const applicationTypes = this.applicationTypes.find(
        applicationTypes =>
          applicationTypes.applicationTypeID === applicationTypeID
      )
      return applicationTypes
        ? applicationTypes.applicationTypeDescription
        : text || ''
    },
    roundRobinProgramCustomLabel ({ programInitials, text }) {
      const program = this.programs.find(
        programs => programs.programInitials === programInitials
      )

      return program ? program.programDescription + ' (' + program.programInitials + ')' : text || ''
    },
    roundRobinCorProgramCustomLabel ({ programInitials, text }) {
      const program = this.programs.find(
        programs => programs.programInitials === programInitials
      )
      return program ? program.programInitials : text || ''
    },
    validateRoundRobinState (name) {
      const { $dirty, $error } = this.$v.roundRobin[name]
      return $dirty ? !$error : null
    },
    onValidateRoundRobinFormSubmit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.saveRoundRobin(this.roundRobin)
      }
    },
    async saveRoundRobin (roundRobin) {
      roundRobin.corporatePrograms.forEach(el => {
        el.isCorporate = true
      })
      roundRobin.nonCorporatePrograms.forEach(el => {
        el.isCorporate = false
      })
      this.roundRobin.roundRobinID
        ? await this.updateRoundRobin(roundRobin)
        : await this.addNewRoundRobin(roundRobin)
      if (this.statusCode === 200) {
        this.setDirtyData(false)
        this.setPreserveMessageAfterPush(true)
        this.$router.push({
          name: 'sales-round-robin',
          params: { roundRobinTypeID: this.roundRobin.roundRobinTypeID }
        })
      }
    },
    cancelRoundRobinForm () {
      this.$router.push({
        name: 'sales-round-robin',
        params: { roundRobinTypeID: this.roundRobin.roundRobinTypeID }
      })
    }
  },
  computed: {
    updatedStates: function () {
      const text = {
        text:
          this.roundRobin.states.length === this.states.length
            ? 'Unselect All'
            : 'Select All'
      }
      return [text, ...this.states]
    },
    updatedAppTypes: function () {
      const text = {
        text:
          this.roundRobin.applicationTypes.length ===
          this.applicationTypes.length
            ? 'Unselect All'
            : 'Select All'
      }
      return [text, ...this.applicationTypes]
    },
    updatedProgramTypes: function () {
      const text = {
        text:
          this.roundRobin.nonCorporatePrograms.length === this.programs.length
            ? 'Unselect All'
            : 'Select All'
      }
      return [text, ...this.programs]
    },
    updatedCorprograms: function () {
      const text = {
        text:
          this.roundRobin.corporatePrograms.length === this.programs.length
            ? 'Unselect All'
            : 'Select All'
      }
      return [text, ...this.programs]
    }
  }
}
</script>
